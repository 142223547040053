import AddHolidayDataUI from './AddHolidayDataUI'
import React, { useState } from 'react'
import { createHoliday } from '../../apis/holiday.api'
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../store/actions/commonAction'
import { closeModal } from '../../store/actions/modalAction'
import { fetchHolidayDataAction } from '../../store/actions/settingsAction'
import moment from 'moment'

const AddHolidayDataController = ({ callBack }) => {

  const dispatch = useDispatch()
  const createHolidayApi = createHoliday
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState(moment())
  const [name, setName] = useState('')
  const [optional, setOptional] = useState(false)
  const [err, setErr] = useState('')
  const { settings } = useSelector((state) => state)

  function toBoolean(value) {
    if (typeof value === "string") {
      return value.toLowerCase() === "true";
    }
    return Boolean(value);
  }

  const addHoliday = (e) => {
    e.preventDefault()

    setLoading(true)
    dispatch(
      callApiAction(
        async () => await createHolidayApi({ date: date.format("YYYY-MM-DD"), name, optional: toBoolean(optional) }),
        (response) => {
          callBack()
          dispatch(closeModal())
          dispatch(fetchHolidayDataAction(settings.holiday_filters))
          setLoading(false)
        },
        (err) => {
          setLoading(false)
          setErr(err)
        },
      ),
    )
  }
  return (
    <AddHolidayDataUI
      loading={loading}
      addHoliday={addHoliday}
      date={date}
      setDate={setDate}
      setName={setName}
      name={name}
      err={err}
      setErr={setErr}
      optional={optional}
      setOptional={setOptional}
    />
  )
}

export default AddHolidayDataController
