import {
  Box,
  Button,
  Checkbox,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Slide,
  Typography,
  TextField,
  CircularProgress,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import CustomInput from '../../components/inputs/CustomInput'
import { CenteredBox } from '../../components/layouts/common/boxes'
import SubmitButton from '../../components/button/SubmitButton'
import { DesktopDatePicker } from '@mui/x-date-pickers'

const AddHolidayDataUI = ({
  loading,
  date,
  addHoliday,
  setDate,
  setName,
  name,
  err,
  setErr,
  optional, setOptional
}) => {
  return (
    <>
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          position: 'relative',
        }}
        maxHeight="100%"
      >


        {loading && (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        )}
        {!loading && (
          <>
            <Box sx={{ marginTop: '10px', width: '100%' }}>
              <Typography color={"red"}>{err}</Typography>
              <Box>
                <Typography fontWeight={500} variant="h4">
                  {' '}
                  Name :{' '}
                </Typography>
              </Box>
              <Box sx={{ marginTop: '7px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', width: '100%' }}>
                    <CustomInput
                      disabled={loading}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      placeholder="Write Name..."
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ marginTop: '10px', width: '100%' }}>
              <Box>
                <Typography fontWeight={500} variant="h4">
                  {' '}
                  Date :{' '}
                </Typography>
              </Box>
              <Box sx={{ marginTop: '7px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', width: '100%' }}>
                    {/* <DatePicker
                      inputFormat='DD/MM/YYYY'
                      onChange={(newValue) => setDate(newValue)}
                      value={date}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: '100%' }}
                          error={false}
                        />
                      )}
                    /> */}
                    <DesktopDatePicker
                      renderInput={(props) => {
                        return <CustomInput {...props} sx={{ height: "100%" }} />;
                      }}
                      inputFormat="DD-MM-YYYY"
                      value={date}
                      onChange={(newValue) => setDate(newValue)}
                      type="date"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ marginTop: '10px', width: '100%' }}>
              <Typography color={"red"}>{err}</Typography>
              <Box>
                <Typography fontWeight={500} variant="h4">
                  {' '}
                  Optional :{' '}
                </Typography>
              </Box>
              <Box sx={{ marginTop: '7px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', width: '100%', border: "1px solid #D1D1D1", borderRadius: "8px", alignItems: "center", p: 1 }}>
                    <RadioGroup sx={{ paddingLeft: "10px", }}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group" defaultValue={optional} onChange={(e) => {
                        console.log("e.target.value", e.target.value)
                        setOptional(e.target.value)
                      }}
                    >
                      <FormControlLabel value={true} control={<Radio />} label="Yes" />
                      <FormControlLabel value={false} control={<Radio />} label="No" />

                    </RadioGroup>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}

        <DialogActions>
          <Box sx={{ float: 'right', marginTop: '7px' }}>
            <SubmitButton
              loading={loading}
              type="submit"
              variant="contained"
              color="primary"
              onClick={addHoliday}
              title={" Add Holiday"}
            >

            </SubmitButton>
          </Box>
        </DialogActions>
      </Box>
    </>
  )
}

export default AddHolidayDataUI
