import {
  Box,
  Skeleton,
  Typography,
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { useSelector } from 'react-redux'
import SubmitButton from '../../../components/button/SubmitButton'
import CustomInput from '../../../components/inputs/CustomInput'
import CustomRadioButtons from '../../../components/layouts/common/CustomRadioButtons'
import { HALF_TYPE, HALF_TYPE_OBJ } from '../../../utils/constants'
import { LEAVE_NAMES, LEAVE_TYPE } from '../../../utils/leave.constants'
import { UserSearchBarNormal } from '../../../components/inputs/SearchBar'
import LeaveBalance from '../LeaveBalance'

const AddManualLeaveRaiseUi = ({
  onSubmit,
  loading,
  fields,
  setFields,
  disableDates,
}) => {
  const { holiday } = useSelector((state) => state)
  const { leaveBalance } = useSelector((state) => state)
  const fullDay = fields.duration === HALF_TYPE_OBJ.FULL_DAY

  const onDateChange = (e) => {
    const changedVal = {}
    changedVal['date'] = e
    setFields({ ...fields, err: '', ...changedVal })
  }

  const optionData = LEAVE_NAMES.filter(
    (item) => item.value !== LEAVE_TYPE.MULTI_LEAVES && item.value !== LEAVE_TYPE.PH && item.value !== LEAVE_TYPE.COFF
  ).map((item) => ({
    value: item.value,
    label: item.value === LEAVE_TYPE.LWP ? item.label : `${item.label}(${leaveBalance?.data?.[item.value - 1] || 0})`,
    disabled: item.value !== LEAVE_TYPE.LWP && (leaveBalance?.data?.[item.value - 1] === 0),
  }))

  console.log("leaveCount", leaveBalance?.data)

  return (
    <Box p={2} mt={1} component="form" onSubmit={onSubmit}>

      <Box mb={2}>
        <Typography variant='h6' color="error">
          {fields.err}
        </Typography>
      </Box>
      <Box sx={{ display: "flex" }} >
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Typography variant='h3' >
            Select Employee:
          </Typography>
          <Box mt={2}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                height: '62px',
              }}
            >
              <Box sx={{ display: 'flex', width: '100%' }}>
                <UserSearchBarNormal
                  placeholder="search"
                  inputProps={{ placeholder: "Search & Select Employee" }}
                  value={fields.userId}
                  onChange={(newVal) => {
                    setFields({ userId: newVal?._id })
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Typography variant='h3' mt={3}>
            Leave Date:
          </Typography>
          <Box mt={2}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                height: '62px',
              }}
            >
              <Box sx={{ display: 'flex', width: '100%' }}>
                <DesktopDatePicker
                  renderInput={(props) => {
                    return <CustomInput {...props} sx={{ height: '100%' }} />
                  }}
                  inputFormat="DD-MM-yyyy"
                  value={fields.date}
                  onChange={onDateChange}
                  type="date"
                />
              </Box>
            </Box>
          </Box>
          {/* <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
            <Checkbox
              checked={fields.moreThanOneDay}
              disabled={
                loading ||
                !(holiday.data && Array.isArray(holiday.data)) ||
                holiday.loading ||
                new Date(
                  fields.from?.get("years"),
                  fields.from?.get("months") + 1,
                  0
                )?.getDate() === fields.from?.get("dates")
              }
              onChange={(e) => {
                const changedVal = {};
                changedVal["moreThanOneDay"] = e.target.checked;

                if (
                  e.target.checked &&
                  fields.duration == HALF_TYPE_OBJ.FIRST_HALF
                ) {
                  changedVal["duration"] = HALF_TYPE_OBJ.SECOND_HALF;
                }

                if (e.target.checked) {
                  changedVal["to"] = moment(fields.date).add(1, "days");
                } else {
                  changedVal["to"] = moment(fields.date);
                }

                changedVal["type"] =
                  fields.type == LEAVE_TYPE.MULTI_LEAVES
                    ? null
                    : LEAVE_TYPE.MULTI_LEAVES;

                if (e.target.checked) {
                  changedVal["to_time_duration"] = HALF_TYPE_OBJ.FULL_DAY;
                } else {
                  changedVal["to_time_duration"] =
                    changedVal["duration"];
                }

                setFields({ ...fields, ...changedVal });
              }}
            />
            <Typography variant="h6">Leave more than one day</Typography>
          </Box>
          {
            <Collapse in={fields.moreThanOneDay}>
              <Box sx={{ display: "flex", width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <DesktopDatePicker
                      minDate={
                        fields.moreThanOneDay
                          ? moment(fields.date).add(1, "days")
                          : undefined
                      }
                      maxDate={
                        fields.moreThanOneDay
                          ? moment(fields.date).endOf("month")
                          : undefined
                      }
                      renderInput={(props) => {
                        return (
                          <CustomInput {...props} sx={{ height: "100%" }} />
                        );
                      }}
                      shouldDisableDate={disableDates}
                      inputFormat="DD-MM-yyyy"
                      disabled={
                        loading ||
                        !(holiday.data && Array.isArray(holiday.data)) ||
                        holiday.loading
                      }
                      value={fields.to}
                      onChange={(e) =>
                        setFields({
                          ...fields,
                          err: "",
                          to: e,
                          type: LEAVE_TYPE.MULTI_LEAVES,
                        })
                      }
                      type="date"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          } */}
          {fields.userId && (
            <Box mt={3}>
              <LeaveBalance userId={fields.userId} date={fields.date} />
            </Box>
          )}
          <>
            <Typography variant='h3' mt={3}>
              Leave Duration:
            </Typography>
            <Box sx={{ display: "flex", flex: "none" }} mt={3}>
              <CustomRadioButtons
                options={HALF_TYPE}
                value={fields.duration}
                onChange={(e) => {
                  setFields({ ...fields, duration: e.target.value })
                }}
              />
            </Box>
          </>
          <>
            <Typography variant='h3' mt={3}>
              Leave Type:
            </Typography>
            <Box sx={{ display: "flex", flex: "none" }} mt={3}>
              {!leaveBalance.loading ? (
                <CustomRadioButtons
                  options={optionData}
                  value={fields.type}
                  onChange={(e) => {
                    setFields({
                      ...fields,
                      type: e.target.value,
                      lwp: false,
                    });
                  }}
                />
              ) : (
                <Skeleton
                  sx={{ height: "50px" }}
                  variant="rounded"
                  width="100%"
                />
              )}
            </Box>
          </>
          <Typography variant='h3' mt={3}>
            Remarks:
          </Typography>
          <CustomInput
            disabled={loading}
            value={fields.remarks}
            onChange={(e) =>
              setFields({ ...fields, err: '', remarks: e.target.value })
            }
            type="text"
            multiline
            placeholder="Write remarks of leave..."
            rows={2}
          />
          <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box sx={{ display: "flex" }} mr={3}>
            </Box>
            <Box sx={{ display: "flex" }}>
              <SubmitButton
                disabled={loading}
                loading={loading}
                type="submit"
                variant="contained"
                color="primary"
                title="Submit"
              >
              </SubmitButton>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }} pl={3} pr={3}>
        </Box>
      </Box>
    </Box>
  )
}
export default AddManualLeaveRaiseUi;
